export default function CityWeather({ city, getCustomIcon }) {
    return (
        <div className="weather-details">
            <div className="weather-info">
                <img className="weather-icon" src={getCustomIcon(city.weather[0]?.icon)} alt="weather icon" />
                <div className="weather-text">
                    <h4 className="temperature">{((city.main.temp * 9 / 5) + 32).toFixed(0)}°F</h4>
                    <h5>{city.weather[0]?.description}</h5>
                </div>
            </div>
            <div>
                <span>{city.name}, {city.state}</span>
                <hr />
                <p>{city.name === 'Memphis' ? 'During' : 'From'} {city.years} Jeffrey was at {city.location} {city.connectionPhrase} {city.jobTitle}</p>
            </div>
        </div>
    );
}