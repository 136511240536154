//Set the page background to gradient or flat 'ivory' for 'video mode'
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../styles/mainStyles.css';

export default function Background() {
    const location = useLocation();

    useEffect(() => {
        const rootElement = document.getElementById('root');
        if (location.pathname === '/interview' || location.pathname === '/intro') {
            rootElement.classList.add('interview-gradient-background');
            rootElement.classList.remove('flat-background');
        } else {
            rootElement.classList.add('flat-background');
            rootElement.classList.remove('interview-gradient-background');
        }
    }, [location.pathname]);

    return null; // This component doesn't render any visible elements
};