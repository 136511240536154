// datetime.js

export function getCurrentTime(isMilitaryTime) {
    const now = new Date();
    const options = { hour: 'numeric', minute: '2-digit', ...(isMilitaryTime ? { hour12: false } : {}) };
    return now.toLocaleTimeString(undefined, options);
}

export function updateClockHands() {
    const now = new Date();
    const second = now.getSeconds();
    const minute = now.getMinutes();
    const hour = now.getHours() % 12;

    const secondDegree = (second / 60) * 360;
    const minuteDegree = (minute / 60) * 360 + (second / 60) * 6;
    const hourDegree = (hour / 12) * 360 + (minute / 60) * 30;

    const secondHand = document.querySelector('.second-hand');
    const minuteHand = document.querySelector('.minute-hand');
    const hourHand = document.querySelector('.hour-hand');

    secondHand.style.transform = `rotate(${secondDegree}deg)`;
    minuteHand.style.transform = `rotate(${minuteDegree}deg)`;
    hourHand.style.transform = `rotate(${hourDegree}deg)`;
}

export function rotateHomeClockHands() {
    const now = new Date();
    const hours = now.getHours() % 12; // Convert to 12-hour format
    const minutes = now.getMinutes();
    
    // Calculate rotation angles for hands
    const hourRotation = (hours * 30) + (minutes * 0.5); // Each hour is 30 degrees, each minute is 0.5 degrees
    const minuteRotation = minutes * 6; // Each minute is 6 degrees

    // Apply rotations
    document.querySelectorAll('.home-clock-hand').forEach(hand => {
        if (hand.classList.contains('home-hour-hand')) {
            hand.style.transform = `rotate(${hourRotation}deg)`;
        } else if (hand.classList.contains('home-minute-hand')) {
            hand.style.transform = `rotate(${minuteRotation}deg)`;
        }
    });
}
