export default function CloseApp({ navigate }) {

    const handleOnClick = () => {
        navigate(-1)
    }

    return (
        <button className="mobile-button" onClick={ handleOnClick } >
            Close App
        </button>
    )
}