// PRE-LOADER IN INDEX.HTML #root <div> 
// Function to calculate and update progress bar width
 const updateProgressBar = (percentage) => {
    const progressBar = document.getElementById('progressBar');
    progressBar.style.width = `${percentage}%`;
};

updateProgressBar(0)

// use Performance to list resources in console that are actively loading.
// when this finishes, the Preloader compoent takes over
const logActivelyLoadingResources = () => {
const resources = performance.getEntriesByType('resource')
const totalResourceCount = 22 //by counting resources manualy, i know there are 22 resources that load until the "DOM" is consdered loaded and 'load' event is triggered
let loadedResourceCount = 0

resources.forEach(resource => {
    if (resource.duration > 0) {
        console.log("Loaded resource count is: ", loadedResourceCount)
        console.log(`Resource "${resource.name}" is actively loading.`);
        loadedResourceCount++;
        // console.log("Loaded resource count is now: ", loadedResourceCount)
        console.log("")
    }
});

const loadingPercentage = (loadedResourceCount / totalResourceCount) * 100;
updateProgressBar(loadingPercentage);

};

// Listen for DOMContentLoaded event to start logging
document.addEventListener('DOMContentLoaded', () => {
// console.log('DOM content loaded. Start logging actively loading resources.');
// Log actively loading resources initially
logActivelyLoadingResources();
});

window.addEventListener('load', () => {
// console.log("finished! stop logging activity. hide preloader")
clearInterval(loggingInterval)
})

// Set interval to continuously log actively loading resources
const loggingInterval = setInterval(logActivelyLoadingResources, 1000);