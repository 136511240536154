import '../../../styles/apps/calculator.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Calculator() {
    
    const navigate = useNavigate();
    const [displayValue, setDisplayValue] = useState('0');

    const handleButtonClick = (keyValue) => {
        switch (keyValue) {
            case 'C':
                setDisplayValue('0');
                break;
            case '=':
                try {
                    setDisplayValue(String(new Function(`return ${displayValue}`)()));
                } catch (error) {
                    setDisplayValue('Error');
                }
                break;
            case 'CLOSE APP':
                navigate(-1);
                break;
            default:
                if (displayValue.length < 7 || displayValue.trim().length === 0) {
                    setDisplayValue(prevValue => prevValue === '0' ? keyValue : prevValue + keyValue);
                }
                break;
        }
    };

    return (
        <main className="app-container">
            <section className="calculator">
                <div className="display">{displayValue}</div>
                <div className="keys">
                    {['C', 'CLOSE APP', '7', '8', '9', '/', '4', '5', '6', '*', '1', '2', '3', '-', '0', '.', '=', '+'].map(key => (
                        <button
                            key={key}
                            className={`${key} ${key === 'C' || key === 'CLOSE APP' ? 'span-two' : ''} ${isNaN(key) && key !== '.' ? 'operator' : 'number'}`}
                            onClick={() => handleButtonClick(key)}
                            data-value={key}
                        >
                            {key}
                        </button>
                    ))}
                </div>
            </section>
        </main>
    );
}
