import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { createRoot } from 'react-dom/client'

import Experience from './Experience.jsx'

const root = createRoot(document.querySelector('#root'))

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Experience />
        </BrowserRouter>
    </React.StrictMode>
)