import '../../../styles/apps/calendar.css';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Header from '../app elements/AppHeader.jsx';
import CloseApp from '../app elements/CloseApp.jsx';

export default function Calendar() {
    const navigate = useNavigate();
    const [dayOfWeek, setDayOfWeek] = useState('');
    const [currentMonth, setCurrentMonth] = useState('');
    const [currentDate, setCurrentDate] = useState('');
    const [currentYear, setCurrentYear] = useState('');  // Added state for the current year
    const [currentQuote, setCurrentQuote] = useState({});
  
    useEffect(() => {
        displayCurrentDate();
        displayQuote();
    }, []);

    function displayCurrentDate() {
        const now = new Date();
        
        // Extract components individually
        const weekday = now.toLocaleDateString(undefined, { weekday: 'long' });
        const month = now.toLocaleDateString(undefined, { month: 'short' });
        const date = now.getDate(); // Get the numeric date
        const year = now.getFullYear(); // Get the current year
    
        // Set states with manual formatting
        setDayOfWeek(weekday.toUpperCase());
        setCurrentMonth(month.toUpperCase());
        setCurrentDate(date);
        setCurrentYear(year);
    }


    function displayQuote() {
        const quotes = [
            { id: 1, quote: "Exist as you are or be as you look.", author: "Rumi", title: "Sufi Poet" },
            { id: 2, quote: "It is not enough that we build products that function, that are understandable and usable, we also need to bring joy and excitement, pleasure and fun, and yes, beauty to people's lives.", author: "Don Norman", title: "Founder of 'UX'" },
            { id: 3, quote: "Row, row, row your boat, gently down the stream. Merrily, Merrily, Merrily, Merrily, Life is but a dream.", author: "Children's Song", title: "Nursery Rhyme" },
            { id: 4, quote: "What is happening here is that television is altering the meaning of ‘being informed’ by creating a species of information that might properly be called disinformation. Disinformation does not mean false information. It means misleading information—misplaced, irrelevant, fragmented or superficial information—information that creates the illusion of knowing something but which in fact leads one away from knowing.", author: "Neil Postman", title: "Author: Amusing Ourselves to Death" },
            { id: 5, quote: "Design is really an act of communication, which means having a deep understanding of the person with whom the designer is communicating.", author: "Don Norman", title: "Founder of 'UX'" },
            { id: 6, quote: "When we are no longer able to change a situation, we are challenged to change ourselves.”", author: "Viktor E. Frankl", title: "Author: Man's Search For Meaning" },
            { id: 7, quote: "...The harder I work, the more I live. I rejoice in life for its own sake. Life is no 'brief candle' for me. It is a sort of splendid torch which I have got hold of for the moment...", author: "George Bernard Shaw", title: "Poem: A Splendid Torch" },
            { id: 8, quote: "Instead of reality being passively recorded by the brain, it is actively constructed by it.", author: "David Eagleman", title: "Neuroscientist, Author: Incognito.." },
            { id: 9, quote: "Creativity is intelligence having fun.", author: "Albert Einstein", title: "Scientist" },
            { id: 10, quote: "Design is intelligence made visible.", author: "Alina Wheeler", title: "Author" },
            { id: 11, quote: "Less is more work.", author: "Golden Krishna", title: "Author: The Best Interface is No Interface" },
            { id: 12, quote: "There is a way out, but the way out is really a way within.", author: "Ken Wilber", title: "Author" },
            { id: 13, quote: "The cave you fear to enter holds the treasure you seek.", author: "Joseph Campbell", title: "Author" },
            { id: 14, quote: "The most important thing about art is to work. Nothing else matters except sitting down every day and trying.", author: "Steven Pressfield", title: "Author: The War of Art" },
            { id: 15, quote: "Social media is biased, not to the Left or the Right, but downward.", author: "Jaron Lanier", title: "Computer Scientist, Author" },
            { id: 16, quote: "Good design is obvious. Great design is transparent.", author: "Joe Sparano", title: "Graphic Designer" },
            { id: 17, quote: "Everything that irritates us about others can lead us to an understanding of ourselves.", author: "Carl Jung", title: "Psychologist/Psychiatrist" },
            { id: 18, quote: "Follow your bliss.", author: "Joseph Campbell", title: "Author" },
            { id: 19, quote: "Art can be said to be - and can be used as - the externalized map of our interior self.", author: "Peter London", title: "Author: No More Secondhand Art" },
            { id: 20, quote: "All things in moderation...including moderation.", author: "Oscar Wilde", title: "Irish Poet" },
            { id: 21, quote: "There is only one thing that I dread: not to be worthy of my sufferings.", author: "Fyodor Dostoevski", title: "Author: The Idiot" },
            { id: 22, quote: "It's not about making your presence known, but making your absence felt.", author: "Colleen Hoover", title: "Author: Without Merit" },
            { id: 23, quote: "Testing with one user early in the project is better than testing with 50 near the end.", author: "Steve Krug", title: "User Testing Guru" },
            { id: 24, quote: "Our job in this lifetime is not to shape ourselves into some ideal we imagine we ought to be, but to find out who we already are and become it.", author: "Steven Pressfield", title: "Author: The War of Art" },
            { id: 25, quote: "By shaping the menus we pick from, technology hijacks the way we perceive our choices and replaces them with new ones. But the closer we pay attention to the options we’re given, the more we’ll notice when they don’t actually align with our true needs.", author: "Tristan Harris", title: "Founder: Center for Humane Technology" },
            { id: 26, quote: "What a liberation to realize that the 'voice in my head' is not who I am. Who am I then? The one who sees that.", author: "Eckhart Tolle", title: "Author" },
            { id: 27, quote: "Be a first rate version of yourself, not a second rate version of someone else.", author: "Judy Garland", title: "Actress" },
            { id: 28, quote: "Our lives are not our own. From womb to tomb, we are bound to others. And with each crime and every kindness, we birth our future.", author: "Cloud Atlas", title: "My Favorite Movie" },
            { id: 29, quote: "You do not have to be good.\nYou do not have to walk on your knees for a hundred miles through the desert, repenting.\nYou only have to let the soft animal of your body\nlove what it loves.\nTell me about despair, yours, and I will tell you mine.\nMeanwhile the world goes on.\nMeanwhile the sun and the clear pebbles of the rain\nare moving across the landscapes,\nover the prairies and the deep trees,\nthe mountains and the rivers.\nMeanwhile the wild geese, high in the clean blue air,\nare heading home again.\nWhoever you are, no matter how lonely,\nthe world offers itself to your imagination,\ncalls to you like the wild geese, harsh and exciting—\nover and over announcing your place\nin the family of things.",
              author: "Mary Oliver", title: "Poem: Wild Geese" },
            { id: 30, quote: "It's not the size of the dog in the fight, it's the size of the fight in the dog.", author: "Mark Twain", title: "Author" },
            { id: 31, quote: "Waste no more time arguing about what a good man should be. Be one.", author: "Marcus Aurelius", title: "Emperor of Rome" },
        ];

        const now = new Date();
        const todaysDate = now.getDate();
        const quoteIndex = todaysDate % quotes.length;
        setCurrentQuote(quotes[quoteIndex])
    }

    return (
        <div className="app-container">
            <header className="app-header">
                <Header title="Daily Quote" />
                <CloseApp navigate={navigate} />
            </header>
            <main className="app-contents">
                <section className="date-display">
                    <div className="dow">{dayOfWeek}</div>
                </section>
                <section className="date-display">
                    <div className="date">{`${currentMonth} ${currentDate}, ${currentYear}`}</div>
                </section>
                <section id="quote-container">
                    <div id="quote">
                        {currentQuote.quote}
                    </div>
                    <hr />
                    <div id="author">                        
                        {currentQuote.author}
                    </div>
                    <div id="job-title">
                        {currentQuote.title}
                    </div>
                </section>
            </main>
        </div>
    );
}