import '../../../styles/apps/portfolio.css';
import Header from '../app elements/AppHeader.jsx';
import CloseApp from '../app elements/CloseApp.jsx';
import { useNavigate } from 'react-router-dom';

export default function WebWork() {
    const navigate = useNavigate()
    return (
        <div className="app-container">
            <header className="app-header">
                <Header title="Web & Dev Work" />
                <CloseApp navigate={navigate} />
            </header>
            <main className="app-contents">
                <span className="portfolio-text">WEB WORK:</span>
                <div className="portfolio-thumbnails">
                    <a href="https://www.why.ico.edu/" target="_blank">
                        <img src="./mobileAssets/webDesign/whyICO-allDevices.png" alt="click to visit recruitment website for an optometry college" />
                        <span>College Recruitment Website &#x2197;</span>
                    </a>
                    <a href="https://www.momentofbrilliance.com/" target="_blank">
                        <img src="./mobileAssets/webDesign/momentOfBrilliance.png" alt="click to visit recruitment website for an optometry college" />
                        <span>Daily Horroscope Website &#x2197;</span>
                    </a>
                    <a href="https://www.alexis-hair-studio.com/" target="_blank">
                        <img src="./mobileAssets/webDesign/alexis-hair-studio.png" alt="click to visit recruitment website for an optometry college" />
                        <span>Small Business Website &#x2197;</span>
                    </a>
                    <a></a>
                </div>
            </main>
        </div>
    );
}