import '../../../styles/contactStyles.css'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../app elements/AppHeader.jsx'
import CloseApp from '../app elements/CloseApp.jsx'

export default function Email() {
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        name: '',
        userEmail: '', // Changed to match the FormKeep parameter name
        message: '',
      });
      const maxLength = 500;
      const rows = Math.ceil(maxLength / 50); // Assuming 50 characters per row
    
      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }

      const handleFormClick = (e) => {
        e.stopPropagation();
      }
    
      // Handle form submission
      const handleSubmit = async (event) => {
        event.preventDefault();
        const url = 'https://formkeep.com/f/d472b9a99186'; //FormKeep action URL
        try {
          const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
              'Content-Type': 'application/json',
            },
          });
          if (response.ok) {
            alert("Thank you! Your message to Jeffrey was successfully sent.")
            setFormData({...formData, message: ''})
          } else {
            throw new Error('Failed to submit form');
          }
        } catch (error) {
          console.error(error);
          alert("Oh no. There was a problem submitting your form. Please try again.");
        }
      };

    return (
        <div className='app-container'>
            <header className="app-header">
                <Header title="Email Jeffrey" />
                <CloseApp navigate={navigate} />
            </header>
            <main className="app-contents">
            <form className="form-width" onClick={handleFormClick} onSubmit={handleSubmit}>
                <section>
                    <label>Your Name</label>
                    <input type="text" name="name" maxLength={75} value={formData.name} onChange={handleChange} className="form-input" />
                </section>
                <section>
                    <label>Your Email *</label>
                    <input type="email" name="userEmail" maxLength={100} value={formData.userEmail} onChange={handleChange} className="form-input" />
                </section>
                <section>
                    <section className="message-characters-label">
                        <label>Your Message * </label>
                        <span>{maxLength - formData.message.length} characters</span>
                    </section>
                    <textarea
                        name="message"
                        maxLength={maxLength}
                        value={formData.message}
                        onChange={handleChange}
                        rows={rows}
                        className="form-input"
                    />
                </section>
                <button type="submit" disabled={!(formData.userEmail.includes('@') && formData.userEmail.includes('.') && formData.message.trim() !== '')} className="submit-button">Send</button>
            </form>
            </main>
        </div>
    )
}