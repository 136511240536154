import '../../../styles/apps/weather.css';
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../app elements/AppHeader.jsx';
import CloseApp from '../app elements/CloseApp.jsx';
import CityWeather from './CityWeather.jsx';

export default function Weather() {
    const navigate = useNavigate();
    const [weatherData, setWeatherData] = useState(null);
    const [otherCitiesWeather, setOtherCitiesWeather] = useState([]);
    const [error, setError] = useState(null);
    const apiKey = '10c00a79a02a93421894a9a9e9fada87';

    const fetchWeatherData = useCallback(async (city) => {

        try {
            const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${apiKey}&units=metric`);
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching weather data:', error);
            setError('Error fetching weather data.');
        }
    }, [apiKey]);

    const fetchOtherCitiesWeather = useCallback(async (cities) => {
        return Promise.all(
            cities.map(async (cityInfo) => {
                try {
                    const response = await fetch(
                        `https://api.openweathermap.org/data/2.5/weather?id=${cityInfo.id}&appid=${apiKey}&units=metric`
                    );
                    const data = await response.json();
                    return {
                        ...data,
                        name: cityInfo.name,
                        state: cityInfo.state,
                        years: cityInfo.years,
                        location: cityInfo.location,
                        connectionPhrase: cityInfo.connectionPhrase,
                        jobTitle: cityInfo.jobTitle,
                    };
                } catch (error) {
                    console.error(`Error fetching weather data for ${cityInfo.name}:`, error);
                    setError(`Error fetching weather data for ${cityInfo.name}.`);
                }
            })
        );
    }, [apiKey]);

    useEffect(() => {
        fetchWeatherData('Chicago').then(setWeatherData);

        const cityData = [
            { id: 5263045, name: 'Milwaukee', state: 'Wisconsin', years: '2015-2017', location: 'The Art Institute of Wisconsin', connectionPhrase: 'working as a', jobTitle: 'Web Instructor and Design Program Coordinator.' },
            { id: 4335045, name: 'New Orleans', state: 'Louisiana', years: '2011-2015', location: 'mumms Software', connectionPhrase: 'working as a', jobTitle: 'Senior UI/UX Designer.' },
            { id: 4315588, name: 'Baton Rouge', state: 'Louisiana', years: '2008-2011', location: 'Louisiana State University', connectionPhrase: 'earning his', jobTitle: 'Master of Fine Art.' },
            { id: 4431410, name: 'Jackson', state: 'Mississippi', years: '2006-2008', location: 'JH&H Architects', connectionPhrase: 'working as an', jobTitle: 'in-house Graphic Designer.' },
            { id: 4641239, name: 'Memphis', state: 'Tennessee', years: 'Spring of 2006', location: 'Creative Inferno', connectionPhrase: 'working as an', jobTitle: 'intern.' },
            { id: 4421935, name: 'Cleveland', state: 'Mississippi', years: '2001-2006', location: 'Delta State University', connectionPhrase: 'earning his', jobTitle: 'Bachelor of Fine Art.' },
        ];

        fetchOtherCitiesWeather(cityData).then(setOtherCitiesWeather);
    }, [fetchWeatherData, fetchOtherCitiesWeather]);

    const getCustomIcon = (iconCode) => {
        return `./mobileAssets/icons-weather/${iconCode}.png`;
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="app-container">
            <header className="app-header">
                <Header title="Today's Weather" />
                <CloseApp navigate={navigate} />
            </header>
            <main className="app-contents">
                <div className="weather-details">
                    {weatherData && (
                        <>
                            <div className="weather-info">
                                <img className="weather-icon" src={getCustomIcon(weatherData.weather[0]?.icon)} alt="weather icon" />
                                <div className="weather-text">
                                    <h2 className="temperature">{((weatherData.main.temp * 9 / 5) + 32).toFixed(0)}°F</h2>
                                    <h4>{weatherData.weather[0]?.description}</h4>
                                </div>
                            </div>
                            <div>
                                <h4> Chicago, Illinois </h4>
                                <hr />
                                <h6>Jeffrey is currently working as Director of Digital Strategies for Illinois College of Optometry.</h6>
                            </div>
                        </>
                    )}
                </div>
                <div>
                    {otherCitiesWeather.map((city, index) => (
                        <CityWeather key={index} city={city} getCustomIcon={getCustomIcon} />
                    ))}
                </div>
            </main>
        </div>
    );
}
