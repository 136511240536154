import React, { useState, useEffect } from 'react';
import '../styles/preloaderStyles.css'

export default function Preloader() {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Simulate loading progress
      setProgress(prevProgress => {
        const newProgress = prevProgress + Math.random() * 10;
        return newProgress >= 100 ? 100 : newProgress;
      });
    }, 500);
    console.log("React's Suspense preloader loading from Preloader.jsx...")
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="preloader">
      <div className="progress">{progress.toFixed(2)}%</div>
      <div className="loading-text">Loading MainNav and MainStage...</div>
    </div>
  );
}