import '../../../styles/apps/notes.css';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../app elements/AppHeader.jsx'
import CloseApp from '../app elements/CloseApp.jsx'

export default function Notes() {

    const navigate = useNavigate()
    const [showDetails, setShowDetails] = useState([false,false]);

    const toggleNoteDetails = (index) => {
        const updatedShowDetails = [...showDetails];
        updatedShowDetails[index] = !updatedShowDetails[index];
        setShowDetails(updatedShowDetails);
    };

    return (
        <div className="app-container">
            <header className="app-header">
                <Header title="Jeffrey's Notes"/>
                <CloseApp navigate={ navigate } />
            </header>
            <main className="app-contents">
                <ul id="notes-list">
                <li className="notes-item" onClick={() => toggleNoteDetails(0)}>
                        <h3>Design Heroes</h3>
                        <div className={`notes-details ${showDetails ? 'show' : 'hide'}`}>
                        <section className="note">
                                <picture srcSet="./mobileAssets/heros/brunoSimon.webp" type="image/webp" />
                                <img src="./mobileAssets/heros/brunoSimon.png" alt="Bruno Simon profile picture" />
                                <section className="note-text">
                                    <span className="name">Bruno Simon</span>
                                    <span className="title">Pioneering Designer & Teacher</span>
                                    His class,<a href="https://threejs-journey.com/" target="_blank"> <em> ThreeJS Journey, </em></a> is the reason this site exists. Thanks Bruno!
                                </section>
                        </section>
                        <section className="note">
                                <picture srcSet="./mobileAssets/heros/mikeMonterio.webp" type="image/webp" />
                                <img src="./mobileAssets/heros/mikeMonterio.png" alt="Mike Monterio profile picture" />
                                <section className="note-text">
                                    <span className="name">Mike Monteiro</span>
                                    <span className="title">Responsible Designer</span>
                                    His lecture,<a href="https://www.youtube.com/watch?v=J0ucEt-La9w&t=11s" target="_blank"> <em> How Designers Destroyed the World, </em></a> is crude, but effective.
                                </section>
                        </section>
                        <section className="note">
                                <picture srcSet="./mobileAssets/heros/victorPapanek.webp" type="image/webp" />
                                <img src="./mobileAssets/heros/victorPapanek.png" alt="Victor Papanek profile picture" />
                                <section className="note-text">
                                    <span className="name">Victor Papanek</span>
                                    <span className="title">Practical Designer</span>
                                    Even though his book <a href="https://www.youtube.com/watch?v=J0ucEt-La9w&t=11s" target="_blank"> <em> Design for the Real World</em></a> was written in the 1970s, much of what's in it still holds true today.
                                </section>
                        </section>
                        <section className="note">
                                <picture srcSet="./mobileAssets/heros/tristanHarris.webp" type="image/webp" />
                                <img src="./mobileAssets/heros/tristanHarris.png" alt="Tristan Harris profile image" />
                                <section className="note-text">
                                    <span className="name">Tristan Harris</span>
                                    <span className="title">Ethical Designer</span>
                                    Check out the <a href="https://www.humanetech.com/" target="_blank">Center for Humane Tech!</a>
                                </section>
                        </section>
                        <section className="note">
                                <picture srcSet="./mobileAssets/heros/donNorman.webp" type="image/webp" />
                                <img src="./mobileAssets/heros/donNorman.png" alt="Don Norman photo" />
                                <section className="note-text">
                                    <span className="name">Don Norman</span>
                                    <span className="title">Father of UX</span>
                                    Just look up anything he has said or written about the subject of 'design.'
                                </section>
                        </section>
                        <section className="note">
                                <picture srcSet="./mobileAssets/heros/goldenKrishna.webp" type="image/webp" />
                                <img src="./mobileAssets/heros/goldenKrishna.png" alt="Golden Krishna photo" />
                                <section className="note-text">
                                    <span className="name">Golden Krishna</span>
                                    <span className="title">Minimal Designer</span>
                                    Author of <a href="https://www.amazon.com/Best-Interface-No-brilliant-technology/dp/0133890333" target="_blank"><em>The Best Interface Is No Interface</em>.</a>
                                </section>
                        </section>
                        <section className="note">
                                <picture srcSet="./mobileAssets/heros/alanTuring.webp" type="image/webp" />
                                <img src="./mobileAssets/heros/alanTuring.png" alt="Alan Turing profile picture" />
                                <section className="note-text">
                                    <span className="name">Alan Turing </span>
                                    <span className="title">Father of Computer Science and A.I.</span>
                                    Ever hear of the <a href="https://en.wikipedia.org/wiki/Turing_test" target="_blank">Turing Test</a>? Alan was the first to consider a computer's ability to "think" ... as early as 1950!
                                </section>
                        </section>
                        <section className="note">
                                <picture srcSet="./mobileAssets/heros/enthanMarcotte.webp" type="image/webp" />
                                <img src="./mobileAssets/heros/ethanMarcotte.png" alt="Ethan Marcotte Profile Image" />
                                <section className="note-text">
                                    <span className="name">Ethan Marcotte</span>
                                    <span className="title">Invented Responsive Design</span>
                                    Read his short book from 2011 <a href="https://abookapart.com/products/responsive-web-design" target="_blank"><em>Responsive Design</em>.</a>
                                </section>
                        </section>
                        </div>
                    </li>
                    <li className="notes-item" onClick={() => toggleNoteDetails(1)}>
                        <h3>Credits and Resources</h3>
                        <div className={`notes-details ${showDetails ? 'show' : 'hide'}`}>
                            <section className="resources">
                                <ul>
                                    <li><a href="https://openweathermap.org/api" target="_blank">Open Weather API</a></li>
                                    <li><a href="https://market.pmnd.rs/model/iphone-x" target="_blank">PMNDR Market: Apple iPhone X Model</a></li>
                                    {/* <li> Apple Agreement to use Macbook (destop) and iPhone (mobile) models in this project.</li> */}
                                    {/* <li><a href="https://fontawesome.com/icons" target="_blank">Font Awesome: Top Bar Icons</a></li> */}
                                    {/* <li><a href="https://www.freepik.com/free-photos-vectors/ios7-icons" target="_blank">FreePik: Phone Icons</a></li> */}
                                    <li><a href="https://pixabay.com/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=102918">Phone Sound Effects from Pixabay:</a>
                                        <ul>
                                            <li>
                                                <a href="https://pixabay.com/sound-effects/search/phone%20ring/" target="_blank">Ringing, </a>
                                                <a href="https://pixabay.com/sound-effects/search/phone%20ring/" target="_blank">No Answer, </a>
                                                <a href="https://pixabay.com/sound-effects/search/phone%20buttons/?pagi=3" target="_blank">Dial Tones</a>
                                            </li>
                                        </ul> 
                                    </li>
                                </ul>
                            </section>
                        </div>
                    </li>
                </ul>
            </main>
        </div>
    )
}