import '../../../styles/apps/clock.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../app elements/AppHeader.jsx';
import CloseApp from '../app elements/CloseApp.jsx';
import { getCurrentTime, updateClockHands } from '../../datetime.js';

export default function Clock() {
  const navigate = useNavigate();
  const [currentTime, setCurrentTime] = useState(new Date());
  const [showExplanation, setShowExplanation] = useState(false);
  const [displayMode, setDisplayMode] = useState(0); // 0: Binary, 1: Regular, 2: Military

  useEffect(() => {
    const clockInterval = setInterval(() => {
      setCurrentTime(new Date());
      updateClockHands();
    }, 1000);

    return () => clearInterval(clockInterval);
  }, []);

  const getBinaryRepresentation = (num) => {
    return [8, 4, 2, 1].map(rowValue => {
      if (num >= rowValue) {
        num -= rowValue;
        return true;
      }
      return false;
    });
  };

  const renderColumn = (binaryArray) => {
    return (
      <div className="dot-column">
        {binaryArray.map((isOn, index) => (
          <div key={index} className={`dot ${isOn ? 'on' : 'off'}`}></div>
        ))}
      </div>
    );
  };

  const renderTimeAsDots = (time) => {
    let hours = time.getHours();
    const minutes = time.getMinutes();
    const seconds = time.getSeconds();

    if (displayMode === 1) { // Regular time mode (12-hour format)
      hours = hours % 12 || 12;
    }

    const hourTens = getBinaryRepresentation(Math.floor(hours / 10));
    const hourOnes = getBinaryRepresentation(hours % 10);
    const minuteTens = getBinaryRepresentation(Math.floor(minutes / 10));
    const minuteOnes = getBinaryRepresentation(minutes % 10);
    const secondTens = getBinaryRepresentation(Math.floor(seconds / 10));
    const secondOnes = getBinaryRepresentation(seconds % 10);

    return (
      <div className="dot-grid">
        {renderColumn(hourTens)}
        {renderColumn(hourOnes)}
        <div className="space" />
        {renderColumn(minuteTens)}
        {renderColumn(minuteOnes)}
        <div className="space" />
        {renderColumn(secondTens)}
        {renderColumn(secondOnes)}
      </div>
    );
  };

  const renderRegularTime = (time, isMilitary) => {
    const hours = isMilitary ? time.getHours() : (time.getHours() % 12 || 12);
    const minutes = time.getMinutes().toString().padStart(2, '0');
    const seconds = time.getSeconds().toString().padStart(2, '0');
    const period = !isMilitary && (time.getHours() >= 12 ? 'PM' : 'AM');

    return (
      <div className="large-time-display">
        {hours}:{minutes}:{seconds} {!isMilitary && period}
      </div>
    );
  };

  const handleChangeDisplayMode = () => {
    setDisplayMode((prevMode) => (prevMode + 1) % 3);
  };

  const renderClock = () => {
    switch (displayMode) {
      case 0:
        return renderTimeAsDots(currentTime); // Binary clock
      case 1:
        return renderRegularTime(currentTime, false); // Regular 12-hour clock
      case 2:
        return renderRegularTime(currentTime, true); // Military 24-hour clock
      default:
        return renderTimeAsDots(currentTime); // Fallback to binary clock
    }
  };

  return (
    <div className="app-container">
      <header className="app-header">
        <Header title="Clock" />
        <CloseApp navigate={navigate} />
      </header>
      <main className="app-contents">
        {renderClock()}

        {/* Explanation only shows when in binary mode */}
        {displayMode === 0 && (
          <div className="info-container">
            <p onClick={() => setShowExplanation(!showExplanation)}>
              This binary clock is a tribute to my coding teachers: Ray LePine and the Papadapamuses.
            </p>
            {showExplanation && (
              <div>
                <br />
                <p><strong>WHAT IS THIS?</strong></p>
                <p>A binary clock displays Time using binary numbers, where each digit of the time is shown in a binary format.</p>
                <br />
                <p><strong>HOW TO READ</strong></p>
                <ol>
                  <li>Each column is a single digit <br /> (HH  MM  SS).</li>
                  <li>Read each row of dots from bottom to top as 1, 2, 4, and 8.</li>
                  <li>ADD the dot's values in any column for its' digit.</li>
                </ol>
                <br />
                <div className="row">
                  8 o
                </div>
                <div className="row">
                  4 ● 
                </div>
                <div className="row">
                  2 o 
                </div>
                <div className="row">
                  1 ● 
                </div>
                <em>Example: This column shows "5" because 1 + 4 = 5.</em>
              </div>
            )}
          </div>
        )}

        <button onClick={handleChangeDisplayMode}>Change Time Display</button>
      </main>
    </div>
  );
}
